<template>
  <div>
    <h2>Select Device</h2>
    <b-btn-group>
      <b-button
        v-for="device in selectableDevices"
        :key="device.id"
        variant="primary"
        @click.prevent="handleDeviceClick(device.id)"
      >
        {{ labelFromDevice(device) }}
      </b-button>
    </b-btn-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'DeviceSelect',
  computed: {
    ...mapState('ping', [
      'selectableDevices'
    ])
  },
  methods: {
    handleDeviceClick(id) {
      this.$store.dispatch('ping/deviceSelect', {
        id
      });
    },
    labelFromDevice(device) {
      switch (device.type) {
        case 'EMAIL':
          return `EMAIL (${device.email})`;
        case 'SMS':
          return `SMS (${device.phone})`;
        case 'MOBILE':
          return `PUSH (${device.model.marketingName})`;
      }
      return device.id;
    }
  }
};
</script>
