<template>
  <b-button
    type="submit"
    variant="primary"
    block
    :disabled="disabled">
    <slot />
  </b-button>
</template>

<script>
export default {
  name: 'SubmitButton',
  props: ['disabled']
}
</script>
