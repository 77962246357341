<template>
<b-form @submit.prevent="handleSubmit">
  <h2>Log in to your account.</h2>

  <b-form-row>
    <b-col>
      <b-form-group>
        <b-input
          name="identifier"
          id="identifier"
          placeholder="Email address"
          autocomplete="identifier"
          autofocus
          v-model="username" />
      </b-form-group>
    </b-col>
  </b-form-row>

  <SubmitButton>Log In</SubmitButton>
</b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'IdentifierForm',
  components: {
    SubmitButton
  },
  data() {
    return {
      username: ''
    }
  },
  methods: {
    handleSubmit() {
      let {
        username
      } = this;
      username = username.toLowerCase();
      this.$store.dispatch('ping/validateIdentifier', {
        username
      });
    }
  }
};
</script>
