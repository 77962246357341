<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Thank You!</h2>
    <p class="lead">We've sent a verification email to your email address. Please verify your email to finish setting up your account.</p>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="text"
            name="verificationCode"
            id="verificationCode"
            placeholder="Verification Code"
            autofocus
            required
            v-model="verificationCode"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Verify</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'UserVerificationForm',
  components: {
    SubmitButton
  },
  data() {
    return {
      verificationCode: ''
    }
  },
  methods: {
    handleSubmit() {
      const { verificationCode } = this;
      this.$store.dispatch('ping/userVerify', {
        verificationCode
      });
    }
  }
};
</script>
