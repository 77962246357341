<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Multifactor Authentication</h2>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="text"
            name="otp"
            id="otp"
            placeholder="One Time Passcode"
            autofocus
            required
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'OtpForm',
  components: {
    SubmitButton
  },
  data() {
    return {
      otp: ''
    }
  },
  methods: {
    handleSubmit() {
      const { otp } = this;
      this.$store.dispatch('ping/validateOtp', {
        otp
      });
    }
  }
};
</script>
