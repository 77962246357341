<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Enter New Password</h2>
    <p><small>If you have an active account with a valid email address, you will receive an email with a recovery code which you may enter here, along with a new password. If you do not have an account or email, please contact your administrator to recover your password.</small></p>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="text"
            name="passwordRecoverCode"
            id="passwordRecoverCode"
            placeholder="Recovery Code"
            autofocus
            required
            v-model="recoveryCode"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="password"
            name="passwordRecoverNewPassword"
            id="passwordRecoverNewPassword"
            placeholder="New Password"
            required
            v-model="newPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <PasswordRequirements :requirements="passwordRequirements" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton :disabled="!this.canSubmit">Save</SubmitButton>
  </b-form>
</template>

<script>
import newPassword from '@/mixins/newPassword';

import PasswordRequirements from '@/components/login/PasswordRequirements.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'AccountRecoveryForm',
  mixins: [
    newPassword
  ],
  components: {
    PasswordRequirements,
    SubmitButton
  },
  data() {
    return {
      recoveryCode: '',
      newPassword: '',
    }
  },
  computed: {
    canSubmit() {
      return this.passwordRequirementsMet && this.recoveryCode.length > 1;
    }
  },
  methods: {
    handleSubmit() {
      const { recoveryCode, newPassword } = this;
      this.$store.dispatch('ping/passwordRecover', {
        recoveryCode,
        newPassword
      });
    }
  }
};
</script>
