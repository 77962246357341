<template>
  <div class="d-flex justify-content-between" v-if="passwordForgotUrl || registerUrl">
    <b-button
      v-if="passwordForgotUrl"
      variant="link"
      size="sm"
      @click.prevent="$store.commit('ping/setView', 'FORGOT_PASSWORD')">
      Forgot Password
    </b-button>
    <b-button
      v-if="registerUrl"
      variant="link"
      size="sm"
      @click.prevent="$store.commit('ping/setView', 'ACCOUNT_REGISTRATION')">
      Create Account
    </b-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'SelfService',
  computed: {
    ...mapState('ping', [
      'passwordForgotUrl',
      'registerUrl'
    ])
  }
}
</script>
