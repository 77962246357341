<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Complete Your Login</h2>
    <p>Please confirm your Identiy Provider E-Mail or Username</p>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            name="accountLinkUsername"
            id="accountLinkUsername"
            placeholder="Username or E-mail Address"
            autocomplete="username"
            autofocus
            v-model="username"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>

  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'AccountLinkingForm',
  components: {
    SubmitButton
  },
  data() {
    return {
      username: '',
      password: 'ZH30c5*iY^T352M1s87o',
    }
  },
  methods: {
    handleSubmit() {
      let { username, password } = this;
      username = this.username.toLowerCase();
      this.$store.dispatch('ping/accountLink', {
        username,
        password
      })
    }
  },
  mounted() {
    if (this.$store.state.ping.accountLinkUsername && this.$store.state.ping.accountLinkUsername !== '') {
      this.username = this.$store.state.ping.accountLinkUsername.toLowerCase();
      this.handleSubmit();
    }
  }
};
</script>
