<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Create Your Profile</h2>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="email"
            name="accountRegEmail"
            id="accountRegEmail"
            placeholder="Email address"
            autofocus
            v-model="email"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            type="password"
            name="accountRegPassword"
            id="accountRegPassword"
            placeholder="Password"
            v-model="newPassword"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <PasswordRequirements :requirements="passwordRequirements" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton :disabled="!canSubmit">Create Account</SubmitButton>

    <SelfService />
  </b-form>
</template>

<script>
import newPassword from '@/mixins/newPassword';

import PasswordRequirements from '@/components/login/PasswordRequirements.vue';
import SelfService from '@/components/login/SelfService.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'AccountRegistrationForm',
  mixins: [
    newPassword
  ],
  components: {
    PasswordRequirements,
    SelfService,
    SubmitButton,
  },
  data() {
    return {
      email: ''
    }
  },
  computed: {
    canSubmit() {
      return this.passwordRequirementsMet;
    }
  },
  methods: {
    handleSubmit() {
      if (this.canSubmit) {
        const { email, newPassword } = this;
        this.$store.dispatch('ping/registerAccount', ({
          username: email,
          email,
          password: newPassword
        }))
      }
    }
  }
};
</script>
