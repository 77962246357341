<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Account Recovery</h2>
    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            name="passwordForgotUsername"
            id="passwordForgotUsername"
            placeholder="Email address"
            autocomplete="username"
            autofocus
            v-model="username"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Request Password Reset</SubmitButton>
  </b-form>
</template>

<script>
import SubmitButton from '@/components/login/SubmitButton.vue';

export default {
  name: 'ForgotPasswordForm',
  components: {
    SubmitButton
  },
  data() {
    return {
      username: ''
    }
  },
  methods: {
    handleSubmit() {
      const { username } = this;
      this.$store.dispatch('ping/passwordForgot', {
        username
      });
    }
  }
};
</script>
