<template>
  <b-form @submit.prevent="handleSubmit">
    <h2>Log in to your account.</h2>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            name="username"
            id="username"
            placeholder="Username or E-mail address"
            autocomplete="username"
            autofocus
            v-model="username"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col>
        <b-form-group>
          <b-input
            ref="password"
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            autocomplete="current-password"
            required
            v-model="password"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <SubmitButton>Log In</SubmitButton>

    <b-button
      v-for="provider in socialProviders"
      variant="primary"
      :key="provider.id"
      :href="provider._links.authenticate.href">
      {{ provider.name }}
    </b-button>

    <SelfService />
  </b-form>
</template>

<script>
import { mapState } from 'vuex';
import SelfService from '@/components/login/SelfService.vue';
import SubmitButton from '@/components/login/SubmitButton.vue';

// I don't fully understand this but I was trying to use this.$nextTick to focus
// on the password field on mounted if username comes back from the Ping API,
// but it wasn't working and this seems to work:
// https://forum.vuejs.org/t/need-to-use-nexttick-twice/28341/4
function flushQueue() {
  return new Promise((resolve) => setTimeout(resolve, 0));
}

export default {
  name: 'LoginForm',
  components: {
    SelfService,
    SubmitButton
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapState('ping', [
      'socialProviders'
    ]),
  },
  methods: {
    handleSubmit() {
      let { username, password } = this;
      username = this.username.toLowerCase();
      this.$store.dispatch('ping/validatePassword', ({
        username,
        password
      }))
    }
  },
  mounted() {
    const username = this.$store.state.ping.flowInfo?.data?._embedded?.user?.username;
    if (username !== 'undefined') {
      this.username = username;
      flushQueue()
        .then(() => {
          this.$refs.password.focus();
        })
    }

  }
};
</script>
